import React from "react";

type StaticProps = {
  setIsDarkbackgroundPage: (isDarkbackgroundPage: boolean) => void;
};

class Static<T> extends React.Component<StaticProps, T> {
  componentDidMount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(false);
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default Static;
