import { Link } from "react-router-dom";
import Static from "../Static";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import {
  ClipboardFill,
  ClipboardCheckFill,
} from "react-bootstrap-icons";

type GenericUsageState = {
  copySuccess: boolean;
};

class GenericUsage extends Static<GenericUsageState> {

  constructor(props: any) {
    super(props);
    this.state = { copySuccess: false };
  }

  componentDidMount() {
    this.props.setIsDarkbackgroundPage(false);

  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  onCopySuccess() {
    this.setState({ copySuccess: true })
  }

  render() {

    return (
      <div>

        <section id="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      Using mailcrumbs
                    </h2>
                    <div className="row center">
                      <p className="p-b-5">Click <Link to='/hc/outlook/usage'>here if you are using Outlook</Link> or <Link to='/hc/gmail/usage'>here if you are using Gmail</Link>.</p>

                      <h3>Sending an email you want to track</h3>
                      <div>

                        <ol>
                          <li className="p-b-5">Add a new tracker in the <Link to="/projects" target="_blank">My trackers</Link> page, by entering the tracker description and clicking on the <b>Add tracker</b> button</li>
                          <img src="/img/generic-add-tracker.png" alt="" className="p-10" />

                          <li className="p-b-5">Each tracker has a code that allows it to uniquely follow a message. You can find the code under the <b>Tracker code</b> entry for your tracker, use the <ClipboardFill /> icon to copy it to your clipboard</li>

                          <li className="p-b-5">The tracker code needs to be attached to a message so it can gather information as the message flows through recipients, how to attach it depends on the email client but in general you will need to add the tracker code as an image at the bottom of your email.</li>

                          <li className="p-b-5">Each time a recipient interacts with the tracked email you will get a notification with the details: who they were, where they opened the email from and what client they used.</li>
                        </ol>
                      </div>

                      <h3>Reviewing where your emails have been</h3>

                      <div>

                        <p>Visit the <Link to="/projects" target="_blank">My trackers</Link> page, if your tracker has already been visited by a recipient you will see a <b>View activity</b> button next to it.</p>
                        <img src="/img/generic-view-activity.png" alt="" className="p-10" />

                        <p>Click on the button to access details of all people that has opened the email, where they were and what client they were using:</p>
                        <img src="/img/generic-tracker-detail.png" alt="" className="p-10" />

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
      </div >
    );
  }
}

export default GenericUsage;
