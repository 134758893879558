import React from "react";

type FooterProps = {
  isOffice: boolean;
};

function Footer({ isOffice }: FooterProps) {
  return (
    <footer id="footer">
      {!isOffice && (
        <div className="copyright-content">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className="social-icons social-icons">
                  <ul>
                    <li className="social-twitter">
                      <a
                        href="https://twitter.com/mailcrumbs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="social-gplus">
                      <a
                        href="mailto:info@mailcrumbs.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-4">
                <a href="/privacy" className="footer__link">Privacy policy</a>
              </div>

              <div className="col-4 text-end">
                <div className="copyright-text">
                  &copy; 2024 mailcrumbs. All Rights Reserved.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
