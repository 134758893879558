import { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import User from "../../classes/User";
import { Outlet } from "react-router";

type LayoutProps = {
  user: User | null;
  logout: () => void;
  isDarkbackgroundPage: boolean;
  isOffice: boolean;
};

function Layout({ user, logout, isDarkbackgroundPage, isOffice }: LayoutProps) {

  useEffect(() => {
    const functions = document.getElementById('functions');

    if (functions) { // This ensures we reload the script and re-setup jquery event
      functions.remove();
    }

    const script = document.createElement("script");

    script.src = "/js/functions.js";
    //script.async = true;
    script.id = 'functions';

    document.body.appendChild(script);
  });

  return (
    <>
      <Header
        user={user}
        logout={logout}
        isDarkbackgroundPage={isDarkbackgroundPage}
        isOffice={isOffice}
      />
      <Outlet />
      <Footer isOffice={isOffice} />
    </>
  );
}

export default Layout;
