/* global Office */

export class MailcrumbsError implements Office.Error {
  code: number;
  message: string;
  name: string;

  constructor(code?: number, name?: string, message?: string) {
    if (code !== undefined && name !== undefined && message !== undefined) {
      this.code = code;
      this.name = name;
      this.message = message;
    } else {
      this.code = 0;
      this.name = "No error encountered";
      this.message = "No error";
    }
  }
}
