import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type LogoutProps = {
  logout: () => void;
};

const Logout = ({ logout }: LogoutProps) => {

  const navigate = useNavigate();

  useEffect(() => {
    async function doLogout() {
      await logout();
      navigate("/");
    }

    doLogout();
  });

  return null;
};

export default Logout;
