import { getToken } from "./TokenManagement";

function updateOptions(options: RequestInit) {
  const update = { ...options };

  const token: string | null = getToken();

  if (token) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return update;
}

export default function fetcher(url: RequestInfo, options?: RequestInit) {
  if (options === undefined) {
    options = {};
  }

  if (options) {
    options = updateOptions(options);
  }

  return fetch(url, options);
}
