import React from "react";
import { Modal, Button } from "react-bootstrap";

interface DeleteConfirmationProps {
  showModal: boolean;
  hideModal: any;
  confirmModal: any;
  deletedObject: any;
  title?: string;
  message: string;
}

const DeleteConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  deletedObject,
  title = "Delete Confirmation",
  message,
}: DeleteConfirmationProps) => {
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => confirmModal(deletedObject)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
