/* global Office */

import { MailcrumbsError } from "./MailcrumbsError";

export class MailcrumbsAsyncResult<T> implements Office.AsyncResult<T> {
  asyncContext: any = undefined;
  diagnostics: any = undefined;
  error: Office.Error = new MailcrumbsError();
  status: Office.AsyncResultStatus = Office.AsyncResultStatus.Failed;
  value!: T;

  contructor(value?: T) {
    if (value) {
      this.value = value;
    }
  }
}
