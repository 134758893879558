import MailcrumbsOutlook from "../classes/MailcrumbsOutlook";

export const getToken = (): string | null => {
  const userToken: string | null =
    MailcrumbsOutlook.getMailcrumbsLoginToken() ||
    localStorage.getItem("token") ||
    sessionStorage.getItem("token");

  if (userToken != null) {
    return userToken;
  }

  return null;
};
