import React from "react";
import { countries } from "country-data";
import LogData from "../../classes/LogData";
import Actor from "../../classes/Actor";
import GPSData from "../../classes/GPSData";

const { DateTime } = require("luxon");

type LogProps = {
  logs: LogData[];
  actors: Actor[];
  onClick: (location: GPSData) => void;
};

type LogState = {};

class Log extends React.Component<LogProps, LogState> {
  state: LogState = {};

  render() {
    let entries: JSX.Element[] = [];

    if (this.props.logs) {
      entries = this.props.logs.map((log, idx) => {
        const localTime = DateTime.fromSeconds(parseInt(log.time));
        const country = countries[log.location.country];
        const countryName = country ? country.name : log.location.country;

        const deviceDescription = log.deviceDescription
          ? `(${log.deviceDescription})`
          : "";

        return (
          <tr
            key={idx}
            onClick={() => this.props.onClick(log.location)}
            style={{ cursor: "pointer" }}
          >
            <td>
              {localTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
            </td>
            {log.actor.edited && <td>{log.actor.name}</td>}
            {!log.actor.edited && (
              <td>
                <i>{log.actor.name}</i>
              </td>
            )}
            <td>
              {log.action} {deviceDescription}
            </td>
            <td>{log.location.isp}</td>
            <td>{log.location.city}</td>
            <td>{countryName}</td>
          </tr>
        );
      });
    }

    return (
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Activity</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table
              className="table table-bordered table-hover"
              id="dataTable"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Actor</th>
                  <th>Action</th>
                  <th>Network</th>
                  <th>City</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Time</th>
                  <th>Actor</th>
                  <th>Action</th>
                  <th>Network</th>
                  <th>City</th>
                  <th>Country</th>
                </tr>
              </tfoot>
              <tbody>{entries}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Log;
