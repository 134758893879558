import { Link } from "react-router-dom";
import Static from "../Static";

type GenericUPrivacyPolicyState = {
};

class PrivacyPolicy extends Static<GenericUPrivacyPolicyState> {

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.setIsDarkbackgroundPage(false);

  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  render() {

    return (
      <div>

        <section id="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      mailcrumbs Privacy policy
                    </h2>
                    <div className="row center">
                      <p><strong>Effective Date: 1st October 2021</strong></p>

                      <p>Welcome to mailcrumbs, an application designed to enhance your productivity and provide you with e-mail intelligence, developed by Jirushi ltd ("we", "us", "our"). Your privacy is paramount to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mailcrumbs application ("App"). Please read this policy carefully to understand our practices regarding your information and how we will treat it.</p>
                      <p>By accessing or using our App, you agree to this Privacy Policy. If you do not agree with our policies and practices, do not download, register with, or use this App.</p>

                      <h4>1. Information We Collect</h4>

                      <p>We may collect information about you in various ways. The information we may collect via the App includes:</p>

                      <div>
                        <ul>
                          <li><b>Personal Data</b>: Personally identifiable information, such as your name, email address, and contact details, that you voluntarily give to us when you choose to participate in various activities related to the App.</li>
                          <li><b>Usage Data</b>: Information about how you access and use the App, including your browsing actions and patterns, as well as information about your device (such as your hardware model, operating system version, unique device identifiers, and network information).</li>
                        </ul>
                      </div>

                      <h4>2. How We Use Your Information</h4>

                      <p>We use the information we collect about you to:</p>

                      <div>
                        <ul>
                          <li>Provide, maintain, and improve the App;</li>
                          <li>Respond to your comments, questions, and requests;</li>
                          <li>Communicate with you about services, offers, promotions, rewards, and events offered by mailcrumbs and others;</li>
                          <li>Monitor and analyze trends, usage, and activities in connection with our App;</li>
                          <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities and protect the rights and property of mailcrumbs and others.</li>
                        </ul>
                      </div>

                      <h4>3. Sharing Your Information</h4>

                      <p>We may share information we collect about you as follows:</p>

                      <div>
                        <ul>
                          <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;</li>
                          <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process;</li>
                          <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of mailcrumbs or others;</li>
                          <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;</li>
                          <li>Between and among mailcrumbs and any current or future parent, subsidiary, and/or affiliated company.</li>
                        </ul>
                      </div>

                      <h4>4. Your Choices</h4>

                      <p>You have choices about the collection and use of your information:</p>

                      <div>
                        <ul>
                          <li><b>Account Information</b>: You may update, correct, or delete information about you at any time by logging into your account and editing your profile.</li>
                        </ul>
                      </div>

                      <h4>5. Data Security</h4>

                      <p>We implement a variety of security measures designed to maintain the safety of your personal information. However, please be aware that no security measures are perfect or impenetrable.</p>

                      <h4>6. Changes to This Privacy Policy</h4>

                      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy.</p>

                      <h4>7. Contact Us</h4>

                      <p>If you have any questions about this Privacy Policy, please contact us at <strong><a href="mailto:info@mailcrumbs.com">info@mailcrumbs.com</a></strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PrivacyPolicy;
