import { useEffect, useState } from "react";
import { registerPushSubscription } from "../../services/UserService";
import { APIResponse } from "../../classes/APIResponse";

const pushServerPublicKey = process.env.REACT_APP_PUSH_PUBLIC_KEY;

function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window && "Notification" in window;
}

function getNotificationPermission() {
  if("Notification" in window) {
    return Notification.permission;
  } else {
    return null;
  }
}

async function askUserPermission() {
  return await Notification.requestPermission();
}

async function registerServiceWorker() {
  return navigator.serviceWorker.register("/pushServiceWorker.js");
}

function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey
  });
}

type PushNotificationProps = {
  displayGranted?: boolean;
}

const PushNotification = ({ displayGranted = false}: PushNotificationProps): JSX.Element => {

  const [userConsent, setUserConsent] = useState(getNotificationPermission());
  const [userSubscribed, setUserSubscribed] = useState(true);

  const pushNotificationSupported = isPushNotificationSupported();

  useEffect(() => {

    const registerAndGetSubscription = async () => {
      let x = await registerServiceWorker();

      let pushSubscription = await getUserSubscription();
      let isSubscribed = !(pushSubscription === null);

      if (!isSubscribed && getNotificationPermission() === 'granted') {
        pushNotificationsSubscribe();
      } else {
        setUserSubscribed(isSubscribed);
      }

    }

    if (pushNotificationSupported) {
      registerAndGetSubscription();
    }
  }, [pushNotificationSupported]);

  async function pushNotificationsSubscribe() {
    const pushSubscription = await createNotificationSubscription()
    const apiResponse: APIResponse = await registerPushSubscription(pushSubscription);

    if (apiResponse.status === 'ok') {
      setUserSubscribed(true);
    } else {
      await pushSubscription.unsubscribe();
      setUserSubscribed(false);
    }
  }

  const clickAskUserConsent = async () => {
    if (pushNotificationSupported) {
      try {
        const consent = await askUserPermission();
        setUserConsent(consent);

        if (consent === "granted") {
          if (!userSubscribed) {
            await pushNotificationsSubscribe();
          }
        }
      } catch (err) {
        setUserSubscribed(false);
      }
    }
  }

  let content = (<div></div>)

  if (!userSubscribed && pushNotificationSupported) {
    switch (userConsent) {
      case 'denied':
        content = (<div className="alert alert-warning"><strong>Warning: New activity alerts will not be shown as notifications are disabled in your browser. You can change this in your browser settings.</strong></div>)
        break;

      case 'granted':
        if(displayGranted) {
          content = (<div className="alert alert-success"><strong>mailcrumbs real-time activity notifications are already enabled on this device.</strong></div>)
        }
        break;

      case 'default':
        content = (<div className="alert alert-dark"><strong>mailcrumbs can show real-time activity notifications, even when your browser and email client are closed.</strong>  <button type="button" className="btn btn-primary btn-sm" onClick={clickAskUserConsent}>Enable notifications</button></div>)
        break;
    }
  }

  return content
}

export default PushNotification;
