import React, { useEffect, useState } from "react";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import turfBbox from "@turf/bbox";
import {
  featureCollection as turfFeatureCollection,
  point as turfPoint,
} from "@turf/helpers";
import geoViewport from "@mapbox/geo-viewport";

import LogData from "../../classes/LogData";
import Actor from "../../classes/Actor";
import Log from "../Log/Log";
import GPSData from "../../classes/GPSData";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { getLogs } from "../../services/DatastoreService";
import useWindowSize, { Size } from "../../hooks/WindowSize";

type TrackerMapProps = {
  trackerId?: string | null;
  isOffice?: boolean;
};

const TrackerMap = ({ trackerId, isOffice = false }: TrackerMapProps) => {

  const size: Size = useWindowSize();

  function setBounds(center: [number, number], zoom: number) {
    setCenter(center);
    setZoom(zoom);
  }

  function centerMap(logs: LogData[]) {
    const ret = centerZoomFromLocations(logs);
    setBounds(ret.center, ret.zoom);
  }

  function focusMarker(center: [number, number]) {
    setBounds(center, 13);
  }

  function handleLogEntryClick(location: GPSData): void {
    focusMarker([location.latitude, location.longitude]);
  }

  function centerZoomFromLocations(
    data: LogData[],
    width: number = 564,
    height: number = 300
  ): { center: [number, number]; zoom: number } {
    const points = data.map((entry) =>
      turfPoint([entry.location.longitude, entry.location.latitude])
    );
    const features = turfFeatureCollection(points);
    const bounds = turfBbox(features);

    const { center, zoom } = geoViewport.viewport(
      [bounds[0], bounds[1], bounds[2], bounds[3]],
      [width, height]
    );

    return {
      center: [center[1], center[0]],
      zoom: Math.min(zoom, 13),
    };
  }

  const onClick = (navigate: any) => {
    navigate(`/projects`);
  };

  const fetchLogs = async () => {
    if (projectId) {
      if (isFetching === false) {
        setIsFetching(true);
        const logs: LogData[] = await getLogs(projectId);
        setLogs(logs);
        setIsFetching(false);
      }
    }
  };

  let { projectId } = useParams();
  const navigate = useNavigate();
  let loadedLogs = useLoaderData() as LogData[];

  if (isOffice && trackerId) {
    projectId = trackerId;
  }

  const actors: Actor[] = [];

  const [center, setCenter] = useState<[number, number]>([0, 0]);
  const [zoom, setZoom] = useState(1);
  const [logs, setLogs] = useState<LogData[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  if (isInitialLoad) {
    setIsInitialLoad(false);
    setLogs(loadedLogs);

    if (isOffice) {
      fetchLogs();
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchLogs();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [isFetching, projectId]);

  //  const ret = centerZoomFromLocations(logs);

  let markers: JSX.Element[] = [];

  if (logs) {
    markers = logs.map((log, idx) => {
      return (
        <Marker
          width={50}
          anchor={[log.location.latitude, log.location.longitude]}
          key={idx}
        />
      );
    });
  }

  return (
    <div>
      <div className="card shadow mb-4">
        {!isOffice && (
          <div className="card-header">
            <div className="blue-msg d-flex justify-content-between">
              <h5 className="m-0 font-weight-bold">Mail tracker</h5>
              <button
                type="button"
                className="btn btn-primary btn-sm m-l-5 text-center"
                onClick={() => onClick(navigate)}
              >
                <i className="fas fa-arrow-left fa-sm m-r-5"></i>Back to
                trackers list
              </button>
            </div>
          </div>
        )}
        <div className="card-body">
          <div className="chart-area">
            <Map center={center} zoom={zoom} animate={true} height={400}>
              <ZoomControl />
              {markers}
            </Map>
          </div>
        </div>
      </div>

      {!isOffice && (
        <Log logs={logs} actors={actors} onClick={handleLogEntryClick} />
      )}
    </div>
  );
};

export default TrackerMap;
