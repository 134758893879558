import { Link } from "react-router-dom";
import Static from "../Static";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import {
  Google
} from "react-bootstrap-icons";

type GmailUsageState = {
  copySuccess: boolean;
};

class GmailUsage extends Static<GmailUsageState> {

  constructor(props: any) {
    super(props);
    this.state = { copySuccess: false };
  }

  componentDidMount() {
    this.props.setIsDarkbackgroundPage(false);

  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  onCopySuccess() {
    this.setState({ copySuccess: true })
  }

  render() {

    return (
      <div>

        <section id="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      Using mailcrumbs for Gmail
                    </h2>
                    <div className="row center">

                      <h3>Sending an email you want to track</h3>
                      <div>

                        <ol>
                          <li className="p-b-5">Add a new tracker in the <Link to="/projects" target="_blank">My trackers</Link> page, by entering the tracker description and clicking on the <b>Add tracker</b> button, then copy the tracker by clicking the <Google /> Google icon.</li>

                          <li className="p-b-5">Compose a new email in Gmail as usual and, before you send the email, click on the <b>Insert photo</b> icon at the bottom and select the <b>Web Address (URL)</b> option. Paste the tracker you previously copied in the <b>Paste an Image url here</b> field and click on <b>Insert</b>, then send the email to start tracking it.</li>

                          <li className="p-b-5">Each time a recipient interacts with the tracked email you will get a notification with the details: who they were, where they opened the email from and what client they used.</li>
                        </ol>
                      </div>

                      <h3>Reviewing where your emails have been</h3>

                      <div>

                        <p>Visit the <Link to="/projects" target="_blank">My trackers</Link> page, if your tracker has already been visited by a recipient you will see a <b>View activity</b> button next to it.</p>
                        <img src="/img/generic-view-activity.png" alt="" className="p-10" />

                        <p>Click on the button to access details of all people that has opened the email, where they were and what client they were using:</p>
                        <img src="/img/generic-tracker-detail.png" alt="" className="p-10" />

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
      </div >
    );
  }
}

export default GmailUsage;
