import {
  APIResponse,
  LoginAPIResponse,
  UserAPIResponse,
} from "../classes/APIResponse";
import fetcher from "./Fetcher";

export async function registerUser(
  username: string,
  password: string
): Promise<APIResponse> {
  let ret: APIResponse = {
    status: "error",
    message: "Error calling user service",
  };

  try {
    const response = await fetcher("/api/user/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    });

    ret = await response.json();
  } catch (error) { }

  return ret;
}

export async function loginUser(
  username: string,
  password: string
): Promise<LoginAPIResponse> {
  let ret: LoginAPIResponse = {
    status: "error",
    message: "Error calling user service",
    data: null,
  };

  try {
    const response = await fetcher("/api/user/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    });

    ret = await response.json();
  } catch (error) { }

  return ret;
}

export async function getUser(): Promise<UserAPIResponse> {
  let ret: UserAPIResponse = {
    status: "error",
    message: "Error calling user service",
    data: null,
  };

  try {
    const response = await fetcher("/api/user/details", {});

    ret = await response.json();
  } catch (error) { }

  return ret;
}

export async function registerPushSubscription(
  pushSubscription: PushSubscription,
): Promise<APIResponse> {
  let ret: APIResponse = {
    status: "error",
    message: "Error registering subscription with push server"
  };

  try {
    const response = await fetcher("/api/user/registerPushSubscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subscription: pushSubscription }),
    });

    ret = await response.json();
  } catch (error) {
//    console.log(error);
   }

  return ret;
}
