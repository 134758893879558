import React from 'react'
import { Navigate } from 'react-router-dom'

type ProtectedProps = {
  enabled: boolean
}

const Protected = (props: React.PropsWithChildren<ProtectedProps>): JSX.Element => {


  console.log(`Protected enabled: ${props.enabled}`);

  if (!props.enabled) {
    return <Navigate to="/" replace />
  }

  return React.createElement(React.Fragment, null, props.children)

}

export default Protected