import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";
import Static from "../Static";

type HomeState = {};

class Home extends Static<HomeState> {

  render() {
    return (
      <>
        <div
          id="slider"
          className="inspiro-slider slider-fullscreen dots-creative"
          data-height-xs="360"
        >
          <Parallax bgImage="/img/parallax/mailcrumbs-sitting.webp" bgImageAlt="track your emails" className="kenburns slide" contentClassName="bg-overlay-fullwidth">
            <div className="bg-overlay"></div>

            <div className="container">
              <div className="slide-captions">
                <h1
                  data-animate="fadeInUp"
                  data-animate-delay="300"
                  className="text-light"
                >
                  Follow the trail
                  <br />
                  of your emails
                </h1>
                <h3
                  data-animate="fadeInUp"
                  data-animate-delay="600"
                  className="text-black-50"
                >
                  Know where your emails have been and who has accessed them
                </h3>

                <div data-animate="fadeInUp" data-animate-delay="900">
                  <Link to="/register">

                    <button
                      type="button"
                      className="btn btn-roundeded btn-reveal btn-reveal-left"
                    >
                      <span>Get started</span>
                      <i className="fa fa-arrow-right"></i>
                    </button>
                  </Link>
                </div>
                <div className="row text-light" style={{ paddingTop: "14%" }}>
                  <div
                    className="col-lg-3"
                    data-animate="fadeInUp"
                    data-animate-delay="1200"
                  >
                    <h3>Know who </h3>
                    <p>
                      <strong>
                        See who received your email, who read it, who it was
                        forwarded to, who printed it, etc.
                      </strong>
                    </p>
                  </div>
                  <div
                    className="col-lg-3"
                    data-animate="fadeInUp"
                    data-animate-delay="1500"
                  >
                    <h3>Location tracking</h3>
                    <p>
                      <strong>
                        See where everyone that has seen your email is and what
                        devices they use.
                      </strong>
                    </p>
                  </div>
                  <div
                    className="col-lg-3"
                    data-animate="fadeInUp"
                    data-animate-delay="1800"
                  >
                    <h3>Forwarded? BCC'd?</h3>
                    <p>
                      <strong>
                        Learn if someone else has read the email apart from
                        your intended recipients.
                      </strong>
                    </p>
                  </div>
                  <div
                    className="col-lg-3"
                    data-animate="fadeInUp"
                    data-animate-delay="2100"
                  >
                    <h3>Works everywhere</h3>
                    <p>
                      <strong>
                        Mail crumbs work on all email clients: Outlook, GMail,
                        Apple Mail, Yahoo!, ...
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
        <section className="background-grey p-t-120 p-b-120" id="details">
          <div className="container">
            <div className="col-lg-8 m-b-100 center">
              <div className="heading-text heading-section text-center">
                <h2>
                  Let mailcrumbs give you the edge in your communications.
                </h2>
                <p>
                  Leverage mailcrumbs technology to help you find all about the
                  communications you send, who receives and what they do with
                  them. Learn if someone else has forwarded your emails and
                  where they are, making your emails work for you and not
                  against you.
                </p>
              </div>
            </div>

            <div className="row call-to-action cta-center">
              <div className="col-sm-3">
                <div className="icon">
                  <img
                    src="/img/email-received.png"
                    className="img-fluid rounded"
                    alt="Receiving email"
                  />
                </div>
              </div>
              <div className="col-sm-9 d-flex align-items-center text-justify">
                <p className="lead text-justify">
                  Mail crumbs attach themselves to the emails that you send and
                  leave a trail when they are opened or forwarded by your
                  recipients. Every time someone interacts with your email, you
                  get an update in real-time.
                </p>
              </div>
            </div>

            <div className="row call-to-action cta-center">
              <div className="col-sm-3">
                <div className="icon">
                  <img
                    src="/img/hiding-behind.png"
                    className="img-fluid rounded"
                    alt="Behind an email"
                  />
                </div>
              </div>

              <div className="col-sm-9 d-flex align-items-center text-justify">
                <p className="lead text-justify">
                  Mail crumbs stay with the email even when it is forwarded.
                  You can see who opened it, where they are and what they do
                  with the email after they read it.
                </p>
              </div>
            </div>

            <div className="row call-to-action cta-center">
              <div className="col-sm-3">
                <div className="icon">
                  <img
                    src="/img/emails-multiple.png"
                    className="img-fluid rounded"
                    alt="Client support"
                  />
                </div>
              </div>
              <div className="col-sm-9 d-flex align-items-center text-justify">
                <p className="lead text-justify">
                  Adding mail crumbs to an email works across all clients as it
                  uses email built-in features, no matter the device you or the
                  recipients use. Mail crumbs always work, they are silent,
                  uninvasive, and discreet.
                </p>
              </div>
            </div>

            <div className="row text-center">
              <h2>Try mailcrumbs for free</h2>
              <Link to="/register">
                <button
                  type="button"
                  className="btn btn-lg btn-roundeded btn-reveal btn-reveal-left"
                >
                  <span>Get started</span>
                  <i className="fa fa-arrow-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </section>
        <Parallax bgImage="/img/parallax/mailcrumbs-smartphone-links.webp" bgImageAlt="track your emails">

          <section
            className="p-t-200 p-b-200 parallax"
          >
          </section>
        </Parallax>
      </>
    );
  }
}

export default Home;
