import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import { useEffect, useState } from "react";

type ErrorBoundaryProps = {
  logout: () => void;
};

const ErrorBoundary = ({ logout }: ErrorBoundaryProps) => {
  const navigate = useNavigate();

  const [error] = useState<any>(useRouteError());
  console.error(error);

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      if (error.status === 401) {
        logout();
        navigate("/login"); // This forces the react element tree to be reset, otherwise after re-loging in this error shows again. Maybe we can improve this in the future
      }
    }
  });

  let errorMessage: string = "Application error";
  let errorMessageDetail: string =
    "An unexpected error has occured, please try again later.";

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorMessage = "Page not found";
      errorMessageDetail =
        "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.";
    }

    if (error.status === 401) {
      // Session has expired or similar, logout user
    }

    if (error.status === 503) {
      errorMessage = "Temporarily unavailable";
      errorMessageDetail =
        "The application is undergoing maintenance, please try again later";
    }
  }

  return (
    <section className="m-t-80 p-b-150">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="page-error-404"> {error.status} </div>
          </div>
          <div className="col-lg-6">
            <div className="text-start">
              <h1 className="text-medium">{errorMessage}</h1>
              <p className="lead">{errorMessageDetail}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorBoundary;
