import Actor from "../classes/Actor";
import { APIResponse } from "../classes/APIResponse";
import LogData from "../classes/LogData";
import ProjectData from "../classes/ProjectData";
import fetcher from "./Fetcher";

export async function getActors(): Promise<Actor[]> {
  const response = await fetcher("/api/actors");

  if (response.ok) {
    return await response.json();
  } else {
    throw new Response(response.statusText, { status: response.status });
  }
}

export async function getLogs(projectId: string): Promise<LogData[]> {
  const response = await fetcher(`/api/logs/${projectId}`);

  if (response.ok) {
    const apiResponse = await response.json();

    if (apiResponse.status === "ok") {
      return await apiResponse.data;
    } else {
      throw new Response(apiResponse.error, { status: 404 });
    }
  } else {
    throw new Response(response.statusText, { status: response.status });
  }
}

export async function getProjects(): Promise<ProjectData[]> {
  const response = await fetcher("/api/projects");

  if (response.ok) {
    const apiResponse = await response.json();
    return await apiResponse.data;
  } else {
    throw new Response(response.statusText, { status: response.status });
  }
}

export async function addProject(description: string): Promise<APIResponse> {
  const response = await fetcher("/api/projects", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ description: description }),
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Response(response.statusText, { status: response.status });
  }
}

export async function removeProject(projectId: string): Promise<APIResponse> {
  const response = await fetcher(`/api/projects/${projectId}`, {
    method: "DELETE",
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Response(response.statusText, { status: response.status });
  }
}
