import { Link } from "react-router-dom";
import Static from "../Static";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import {
  ClipboardFill,
  ClipboardCheckFill,
} from "react-bootstrap-icons";
import PushNotification from "../../PushNotification/PushNotification";

type WelcomeState = {
  isOutlookApp: boolean;
  copySuccess: boolean;
};

class Welcome extends Static<WelcomeState> {

  constructor(props: any) {
    super(props);
    this.state = { isOutlookApp: true, copySuccess: false };
  }

  componentDidMount() {
    this.props.setIsDarkbackgroundPage(false);
  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  onClickOutlookApp() {
    this.setState({ isOutlookApp: true })
  }

  onClickOutlookWeb() {
    this.setState({ isOutlookApp: false })
  }

  onCopySuccess() {
    this.setState({ copySuccess: true })
  }

  render() {

    let outlookAppButtonClass, outlookWebButtonClass, outlookUrl;

    if (this.state.isOutlookApp) {
      outlookAppButtonClass = 'btn btn-roundeded m-l-10 btn-dark'
      outlookWebButtonClass = 'btn btn-roundeded m-l-10 btn-light'
      outlookUrl = '/hc/outlook/usage#app'

    } else {
      outlookAppButtonClass = 'btn btn-roundeded m-l-10 btn-light'
      outlookWebButtonClass = 'btn btn-roundeded m-l-10 btn-dark'
      outlookUrl = '/hc/outlook/usage#web'
    }

    return (
      <div>

        <section id="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      Welcome to mailcrumbs, let's get started...
                    </h2>
                    <div className="row center">

                      <div>

                        <h3>Receiving desktop notifications</h3>

                        <PushNotification displayGranted />

                        <h3>Setting up mailcrumbs</h3>

                        <p className="p-b-5">mailcrumbs work across all email programs, start by choosing yours from below:</p>

                        <Link to='/hc/outlook/install'><img src="/img/clients-outlook.png" width="200" alt="Microsoft Outlook" className="p-10" /></Link>
                        <p/>

                        <Link to='/hc/gmail/usage'><img src="/img/clients-gmail.png" width="200" alt="Google gmail" className="p-10" /></Link>

                        <p className="p-b-5">... or <Link to='/hc/generic/usage'>click here if you are using another email program</Link>.</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




      </div>
    );
  }
}

export default Welcome;
