import {
  Form,
  ErrorMessage,
  Field,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import * as yup from "yup";
import YupPassword from "yup-password";
import { LoginAPIResponse } from "../../classes/APIResponse";
import { loginUser } from "../../services/UserService";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import User from "../../classes/User";
YupPassword(yup); // extend yup

type LoginProps = {
  user: User | null | undefined;
  setToken: (rememberMe: boolean, token: string) => Promise<boolean>;
  isOffice: boolean;
};

interface Values {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const doLogin = async (
  email: string,
  password: string,
  rememberMe: boolean,
  setToken: (rememberMe: boolean, token: string) => Promise<boolean>
): Promise<boolean> => {
  let ret: boolean = false;

  const loginResponse: LoginAPIResponse = await loginUser(email, password);

  if (loginResponse.status === "ok" && loginResponse.data) {
    const token = loginResponse.data.token;
    ret = await setToken(rememberMe, token);
  } else {
    toast.error(`${loginResponse.message}`);
  }

  return ret;
};

const Login = ({ user, setToken, isOffice }: LoginProps) => {

  const navigate = useNavigate();

  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const onSubmit = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    setSubmitting(true);
    const loginSuccess = await doLogin(
      values.email,
      values.password,
      values.rememberMe,
      setToken
    );

    if (loginSuccess) {
      navigate("/projects");
    }

    setSubmitting(false);
  };

  if (user != null) {
    navigate("/");
    return null;
  } else {
    return (
      <section
        className="pt-5 pb-5"
        data-bg-image="img/parallax/mailcrumbs-laptop.webp"
      >
        <div className="container-fluid d-flex flex-column">
          <div className="row align-items-center min-vh-100">
            <div className="col-md-6 col-lg-4 col-xl-3 mx-auto">
              <div className="card">
                <div className="card-body py-5 px-sm-5">
                  <div className="mb-5 text-center">
                    <h6 className="h3 mb-1">Login</h6>
                    <p className="text-muted mb-0">
                      Sign in to your account to continue.
                    </p>
                  </div>
                  <span className="clearfix"></span>

                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      rememberMe: true,
                    }}
                    onSubmit={onSubmit}
                    validationSchema={loginSchema}
                  >
                    {(props: FormikProps<Values>) => {
                      const { touched, errors, isSubmitting } = props;

                      return (
                        <Form className="form-validate">
                          <div className="form-group">
                            <label htmlFor="email">Email address</label>

                            <div className="input-group">
                              <Field
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                className={`form-control form-control-user ${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <span className="input-group-text">
                                <i className="icon-user"></i>
                              </span>
                            </div>

                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="password">Password</label>

                            <div className="input-group show-hide-password">
                              <Field
                                type="password"
                                name="password"
                                placeholder="Password"
                                className={`form-control form-control-user ${
                                  touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <span className="input-group-text">
                                <i
                                  className="icon-eye-off"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>

                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <div className="custom-control custom-checkbox small">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="rememberMe"
                                  className={`custom=control-input ${
                                    touched.password && errors.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                &nbsp;Remember me
                              </label>
                              <ErrorMessage
                                name="rememberMe"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary btn-user btn-block"
                            disabled={isSubmitting}
                          >
                            Sign in
                          </button>

                          {!isOffice && (
                            <div className="mt-4 text-center">
                              <small>Not registered?</small>{" "}
                              <Link to="/register" className="small fw-bold">
                                Create account
                              </Link>
                            </div>
                          )}

                          {isOffice && (
                            <div className="mt-4 text-center">
                              <small>Not registered?</small>{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://mailcrumbs.com/register"
                                className="small fw-bold"
                              >
                                Create account
                              </a>
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Login;
