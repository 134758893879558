/* global Office */

import { getUser } from "../services/UserService";
import { MailcrumbsAsyncResult } from "./MailcrumbsAsyncResult";
import { MailcrumbsError } from "./MailcrumbsError";

type DialogMessageResultOK = {
  message: string;
  origin: string | undefined;
};

type DialogMessageResultError = {
  error: number;
};

export default class MailcrumbsOutlook {
  /* TODO: Check if this has been forwarded as it inherits custom properties */

  private static TRACKER_KEY = "trackerId";
  private static LOGINTOKEN_KEY = "loginToken";
  private static LOGIN_URL =  `${process.env.REACT_APP_MAILCRUMBS_FRONTEND}/office/`;
  public static MAPS_URL = `${process.env.REACT_APP_MAILCRUMBS_FRONTEND}/maps/`;

  public static getMailcrumbsTrackerId = (
    item: Office.MessageRead | Office.MessageCompose,
    callback: (result: Office.AsyncResult<string>) => void
  ) => {
    let result = new MailcrumbsAsyncResult<string>();

    if (item) {
      item.loadCustomPropertiesAsync(
        (asyncResult: Office.AsyncResult<Office.CustomProperties>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            let customProperties: Office.CustomProperties = asyncResult.value;

            let mailcrumbsKey: string = customProperties.get(
              MailcrumbsOutlook.TRACKER_KEY
            );

            result.value = mailcrumbsKey;
            result.status = Office.AsyncResultStatus.Succeeded;

            callback(result);
          } else {
            result.status = Office.AsyncResultStatus.Failed;
            result.error = new MailcrumbsError(
              1,
              "Invalid properties",
              "Unable to load custom properties"
            );
            callback(result);
          }
        }
      );
    } else {
      result.status = Office.AsyncResultStatus.Failed;
      result.error = new MailcrumbsError(
        1,
        "Invalid item",
        "Invalid undefined item"
      );
      callback(result);
    }
  };

  public static setMailcrumbsTrackerId = (
    item: Office.MessageRead | Office.MessageCompose,
    trackerId: string,
    callback: (result: Office.AsyncResult<void>) => void
  ) => {
    let result = new MailcrumbsAsyncResult<void>();

    if (item) {
      item.loadCustomPropertiesAsync(
        (asyncResult: Office.AsyncResult<Office.CustomProperties>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            let customProperties: Office.CustomProperties = asyncResult.value;

            customProperties.set(MailcrumbsOutlook.TRACKER_KEY, trackerId);

            customProperties.saveAsync(
              (asyncResult: Office.AsyncResult<void>) => {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                  result.status = Office.AsyncResultStatus.Succeeded;
                  callback(result);
                } else {
                  result.status = Office.AsyncResultStatus.Failed;
                  result.error = new MailcrumbsError(
                    1,
                    "Properties saving",
                    "Unable to save custom properties"
                  );
                  callback(result);
                }
              }
            );
          } else {
            result.status = Office.AsyncResultStatus.Failed;
            result.error = new MailcrumbsError(
              1,
              "Invalid properties",
              "Unable to load custom properties"
            );
            callback(result);
          }
        }
      );
    } else {
      result.status = Office.AsyncResultStatus.Failed;
      result.error = new MailcrumbsError(
        1,
        "Invalid item",
        "Invalid undefined item"
      );
      callback(result);
    }
  };

  public static getMailcrumbsLoginToken = (): string => {
    let mailCrumbsLoginToken = null;

    if (typeof Office !== "undefined") {
      if (Office && Office.context && Office.context.roamingSettings) {
        mailCrumbsLoginToken = Office.context.roamingSettings.get(
          MailcrumbsOutlook.LOGINTOKEN_KEY
        );
      }
    }

    return mailCrumbsLoginToken;
  };

  public static setMailcrumbsLoginToken = (
    loginToken: string,
    callback: (result: Office.AsyncResult<void>) => void
  ) => {
    Office.context.roamingSettings.set(
      MailcrumbsOutlook.LOGINTOKEN_KEY,
      loginToken
    );
    Office.context.roamingSettings.saveAsync(callback);
  };

  public static deleteMailcrumbsLoginToken = () => {
    Office.context.roamingSettings.remove(MailcrumbsOutlook.LOGINTOKEN_KEY);
  };

  public static loginUser(
    callback: (asyncResult: Office.AsyncResult<string>) => void
  ) {
    let result = new MailcrumbsAsyncResult<string>();

    MailcrumbsOutlook.deleteMailcrumbsLoginToken();

    Office.context.ui.displayDialogAsync(
      this.LOGIN_URL,
      (dialogDisplayResult: Office.AsyncResult<Office.Dialog>) => {
        if (dialogDisplayResult.status === Office.AsyncResultStatus.Succeeded) {
          let dialog: Office.Dialog = dialogDisplayResult.value;

          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            (
              dialogMessageResult:
                | DialogMessageResultOK
                | DialogMessageResultError
            ) => {
              dialog.close();

              if ("error" in dialogMessageResult) {
                result.status = Office.AsyncResultStatus.Failed;
                result.error = new MailcrumbsError(
                  1,
                  "Login dialog message error",
                  `Error retrieving message from login dialog: ${dialogMessageResult.error}`
                );
                callback(result);
              } else {
                let loginToken: string = dialogMessageResult.message;

                MailcrumbsOutlook.setMailcrumbsLoginToken(
                  loginToken,
                  (loginTokenSaveResult: Office.AsyncResult<void>) => {
                    if (
                      loginTokenSaveResult.status ===
                      Office.AsyncResultStatus.Succeeded
                    ) {
                      result.status = Office.AsyncResultStatus.Succeeded;
                      result.value = loginToken;
                      callback(result);
                    } else {
                      result.status = Office.AsyncResultStatus.Failed;
                      result.error = new MailcrumbsError(
                        1,
                        "Login token save error",
                        `Error storing login token: ${loginTokenSaveResult.error}`
                      );
                      callback(result);
                    }
                  }
                );
              }
            }
          );

          dialog.addEventHandler(
            Office.EventType.DialogEventReceived,
            (
              dialogEventResult:
                | DialogMessageResultOK
                | DialogMessageResultError
            ) => {
              if ("error" in dialogEventResult) {
                switch (dialogEventResult.error) {
                  case 12002: // Invalid URL
                    result.status = Office.AsyncResultStatus.Failed;
                    result.error = new MailcrumbsError(
                      1,
                      "Login dialog event error",
                      `Invalid login dialog URL`
                    );
                    callback(result);
                    break;

                  case 12006: // Login dialog closed by user
                    result.status = Office.AsyncResultStatus.Failed;
                    result.error = new MailcrumbsError(
                      1,
                      "Login dialog event error",
                      `Login dialog closed before authentication was completed`
                    );
                    callback(result);
                    break;
                }
              }
            }
          );
        } else {
          result.status = Office.AsyncResultStatus.Failed;
          result.error = new MailcrumbsError(
            1,
            "Login dialog display error",
            `Error displaying login dialog: ${dialogDisplayResult.error.message}`
          );
          callback(result);
        }
      }
    );
  }

  public static getLoginToken = async (
    callback: (result: Office.AsyncResult<string>) => void
  ): Promise<void> => {
    let result = new MailcrumbsAsyncResult<string>();

    let loginToken = MailcrumbsOutlook.getMailcrumbsLoginToken();
    let loginTokenUsable: boolean = false;

    if (loginToken) {
      try {
        let userDetails = await getUser();

        if (userDetails.status === "ok") {
          loginTokenUsable = true;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    if (loginToken && loginTokenUsable) {
      result.status = Office.AsyncResultStatus.Succeeded;
      result.value = loginToken;
      callback(result);
    } else {
      MailcrumbsOutlook.loginUser(callback);
    }
  };
}
