import { Link } from "react-router-dom";
import Static from "../Static";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import {
  ClipboardFill,
  ClipboardCheckFill,
} from "react-bootstrap-icons";

type OutlookInstallState = {
  isOutlookApp: boolean;
  copySuccess: boolean;
};

class OutlookInstall extends Static<OutlookInstallState> {

  constructor(props: any) {
    super(props);
    this.state = { isOutlookApp: true, copySuccess: false };
  }

  componentDidMount() {
    this.props.setIsDarkbackgroundPage(false);
  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  onClickOutlookApp() {
    this.setState({ isOutlookApp: true })
  }

  onClickOutlookWeb() {
    this.setState({ isOutlookApp: false })
  }

  onCopySuccess() {
    this.setState({ copySuccess: true })
  }

  render() {

    let outlookAppButtonClass, outlookWebButtonClass, outlookUrl;

    if (this.state.isOutlookApp) {
      outlookAppButtonClass = 'btn btn-roundeded m-l-10 btn-dark'
      outlookWebButtonClass = 'btn btn-roundeded m-l-10 btn-light'
      outlookUrl = '/hc/outlook/usage#app'

    } else {
      outlookAppButtonClass = 'btn btn-roundeded m-l-10 btn-light'
      outlookWebButtonClass = 'btn btn-roundeded m-l-10 btn-dark'
      outlookUrl = '/hc/outlook/usage#web'
    }

    return (
      <div>

        <section id="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      Installing mailcrumbs for Outlook
                    </h2>
                    <div className="row center">
                      <h4 className="">I am using...
                        <button
                          type="button"
                          className={outlookAppButtonClass}
                          onClick={(e) => this.onClickOutlookApp()}
                        >
                          <span>Outlook app on my computer</span>
                        </button>

                        <button
                          type="button"
                          className={outlookWebButtonClass}
                          onClick={(e) => this.onClickOutlookWeb()}
                        >
                          <span>Outlook on the web</span>
                        </button>
                      </h4>

                      <div>

                        {this.state.isOutlookApp && <ol>

                          <li className="p-b-5">In Outlook, click <b>Get Add-ins</b> from the <b>Home</b> tab on the ribbon. You can also select <b>File</b> &gt; <b>Manage Add-ins</b>, which will take you to Outlook on the web and open the Add-Ins for Outlook dialog there.</li>
                          <img src="/img/outlook-app-get-add-ins.png" alt="" className="p-10" />

                          <li className="p-b-5">Once in the <b>Add-Ins</b> dialog, type <b>mailcrumbs</b> in the search box, and click on the mailcrumbs icon that appears:</li>
                          <img src="/img/outlook-web-search.png" alt="" className="p-10" />

                          <li className="p-b-5">Click on the <b>Add</b> button to complete the installation:</li>
                          <img src="/img/outlook-web-add.png" alt="" className="p-10" />

                          <li className="p-b-5">mailcrumbs should now be listed in the <b>Custom Addins</b> section, you can now close the <b>Add-Ins for Outlook</b> page to return to Outlook</li>
                        </ol>}

                        {!this.state.isOutlookApp && <ol>
                          <li className="p-b-5">Open Outlook on the web</li>
                          
                          <li className="p-b-5">Click on the <b>Get add-ins</b> icon </li>
                          <img src="/img/outlook-web-get-add-ins.png" alt="" className="p-10" />

                          <li className="p-b-5">Once in the <b>Add-Ins</b> dialog, type <b>mailcrumbs</b> in the search box, and click on the mailcrumbs icon that appears:</li>
                          <img src="/img/outlook-web-search.png" alt="" className="p-10" />

                          <li className="p-b-5">Click on the <b>Add</b> button to complete the installation:</li>
                          <img src="/img/outlook-web-add.png" alt="" className="p-10" />

                          <li className="p-b-5">mailcrumbs should now be listed in the <b>Custom Addins</b> section, you can now close the <b>Add-Ins for Outlook</b> page to return to Outlook</li>
                        </ol>}
                      </div>
                    </div>

                    <div className="row text-center">
                      <Link to={outlookUrl}>
                        <button
                          type="button"
                          className="btn btn-roundeded btn-reveal btn-reveal-left"
                        >
                          <span>First steps with mailcrumbs for Outlook</span>
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




      </div>
    );
  }
}

export default OutlookInstall;
