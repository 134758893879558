import React, { useEffect } from "react";
import toast from "react-hot-toast";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikHelpers,
  FormikProps,
} from "formik";
import * as yup from "yup";
import YupPassword from "yup-password";
import { APIResponse } from "../../classes/APIResponse";
import { registerUser } from "../../services/UserService";
import { doLogin } from "../Login/Login";
import { Link, useNavigate } from "react-router-dom";
import User from "../../classes/User";

YupPassword(yup); // extend yup

type RegisterProps = {
  user: User | null;
  setToken: (rememberMe: boolean, token: string) => Promise<boolean>;
};

interface Values {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const Register = ({ user, setToken }: RegisterProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    const functions = document.getElementById('functions');

    if (!functions) {
      const script = document.createElement("script");

      script.src = "/js/functions.js";
      script.async = true;
      script.id = 'functions';

      document.body.appendChild(script);
    }
  });

  const registrationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().min(6).minNumbers(1),
    passwordConfirmation: yup
      .string()
      .required("Please retype your password.")
      .oneOf([yup.ref("password")], "Passwords must match."),
  });

  const onSubmit = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    setSubmitting(true);

    const registerResponse: APIResponse = await registerUser(
      values.email,
      values.password
    );

    setSubmitting(false);

    if (registerResponse.status === "ok") {
      toast.success(`User successfully registered, logging in`);
      const loginSuccess = await doLogin(
        values.email,
        values.password,
        true,
        setToken
      );

      if (loginSuccess) {
        navigate("/welcome");
      }
    } else {
      // Show error message
      toast.error(`${registerResponse.message}`);
    }
  };

  if (user != null) {
    navigate("/");
    return null;
  } else {
    return (
      <section
        className="pt-5 pb-5"
        data-bg-image="img/parallax/mailcrumbs-laptop.webp"
      >
        <div className="container-fluid d-flex flex-column">
          <div className="row align-items-center min-vh-100">
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <div className="card shadow-lg">
                <div className="card-body py-5 px-sm-5">
                  <h3>Get started for free.</h3>
                  <p>
                    Create a mailcrumbs account by entering the information
                    below.
                  </p>

                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      passwordConfirmation: "",
                    }}
                    onSubmit={onSubmit}
                    validationSchema={registrationSchema}
                  >
                    {(props: FormikProps<Values>) => {
                      const { touched, errors, isSubmitting } = props;

                      return (
                        <Form className="form-validate mt-5">
                          <div className="h5 mb-4">Account details</div>
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="email">Email address</label>
                              <Field
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                className={`form-control form-control-user ${touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="password">Password</label>
                              <div className="input-group show-hide-password">
                                <Field
                                  type="password"
                                  name="password"
                                  placeholder="Enter password"
                                  className={`form-control form-control-user ${touched.password && errors.password
                                      ? "is-invalid"
                                      : ""
                                    }`}
                                />
                                <span className="input-group-text">
                                  <i
                                    className="icon-eye"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="passwordConfirmation">
                                Confirm Password
                              </label>
                              <div className="input-group show-hide-password">
                                <Field
                                  type="password"
                                  name="passwordConfirmation"
                                  placeholder="Repeat password"
                                  className={`form-control form-control-user ${touched.passwordConfirmation &&
                                      errors.passwordConfirmation
                                      ? "is-invalid"
                                      : ""
                                    }`}
                                />
                                <span className="input-group-text">
                                  <i
                                    className="icon-eye-off"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <ErrorMessage
                                  name="passwordConfirmation"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0"></div>

                            <div className="col-sm-6"></div>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-user btn-block"
                            disabled={isSubmitting}
                          >
                            Register Account
                          </button>

                          <div className="mt-4 text-center">
                            <small>Already have an account?</small>{" "}
                            <Link to="/login" className="small fw-bold">
                              Sign In
                            </Link>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Register;
