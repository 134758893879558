import { useState } from "react";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import * as yup from "yup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  ClipboardFill,
  ClipboardCheckFill,
  Envelope,
  Google,
} from "react-bootstrap-icons";

import { APIResponse } from "../../classes/APIResponse";
import ProjectData from "../../classes/ProjectData";
import {
  addProject,
  getProjects,
  removeProject,
} from "../../services/DatastoreService";
import toast from "react-hot-toast";
import { useLoaderData, useNavigate } from "react-router-dom";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import InfoModal from "../InfoModal/InfoModal";
import PushNotification from "../PushNotification/PushNotification";

const { DateTime } = require("luxon");

interface Values {
  description: string;
}

const escapeHtml = (unsafe: string) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const onClick = (navigate: any, project: ProjectData) => {
  navigate(`/maps/${project.id}`);
};

const onClickAdd = async (
  setProjects: (value: React.SetStateAction<ProjectData[]>) => void,
  values: Values,
  { setSubmitting }: FormikHelpers<Values>
) => {
  setSubmitting(true);

  const apiResponse: APIResponse = await addProject(values.description);

  if (apiResponse.status === "ok") {
    toast.success("Tracker added successfully");
    values.description = "";
    const projects = await getProjects();
    setProjects(projects);
  } else {
    toast.error(apiResponse.message);
  }

  setSubmitting(false);
};

function Project() {
  const [copySuccess, setCopySuccess] = useState(-1);
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [deleteModalObject, setDeleteModalObject] = useState<ProjectData>();
  const [deleteModalMessage, setDeleteModalMessage] = useState<string>("");
  const [deleteModalDisplay, setDeleteModalDisplay] = useState(false);
  const [infoModalDisplay, setInfoModalDisplay] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState<string>("");

  const showDeleteModal = (project: ProjectData) => {
    setDeleteModalObject(project);
    setDeleteModalMessage(
      `Are you sure you want to delete tracker '${project.description}'?`
    );
    setDeleteModalDisplay(true);
  };

  const showInfoModal = (embeddingCode: string) => {
    const encodedEmbeddingCode = escapeHtml(embeddingCode);

    const message = `<p>Trackers need to be attached to messages so they can gather information as the message flows through recipients.</p>
    <p>The tracker code for this project is:</p>
    <p class="m-l-5"><code>${encodedEmbeddingCode}</code></p>
    <p>Each email client uses a different approach to embed trackers within messages, here are instructions for the most common clients:</p>
    <ul>
    <li><a href="https://www.emailonacid.com/help-article/how-can-i-send-a-tracking-pixel-with-outlook/">Outlook</a>
    <li><a href="https://www.cedarville.edu/insights/computer-help/post/how-to-add-an-image-to-an-email-in-google-mail">GMail</a>, use the "Web Address (URL)" option
    </ul>
    `;

    setInfoModalMessage(message);
    setInfoModalDisplay(true);
  };

  const hideConfirmationModal = () => {
    setDeleteModalDisplay(false);
  };

  const hideInfoModal = () => {
    setInfoModalDisplay(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = async (project: ProjectData) => {
    const apiResponse: APIResponse = await removeProject(project.id);

    if (apiResponse.status === "ok") {
      toast.success("Tracker deleted successfully");
      const projects = await getProjects();
      setProjects(projects);
      setDeleteModalDisplay(false);
    } else {
      toast.error(apiResponse.message);
    }
  };

  const navigate = useNavigate();

  const loadedProjects: ProjectData[] = useLoaderData() as ProjectData[];

  if (isInitialLoad) {
    setIsInitialLoad(false);
    setProjects(loadedProjects);
  }

  const projectSchema = yup.object().shape({
    description: yup.string().required(),
  });

  const entries = projects.map((project, idx) => {
    let lastActivityMessage = "No activity observed";

    if (project.lastActivityTime) {
      const lastActivityLocalTime = DateTime.fromSeconds(
        parseInt(project.lastActivityTime)
      );
      lastActivityMessage = lastActivityLocalTime.toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS
      );
    }

    let embeddingCode = `<img src="${project.fullUrl}">`;
    let trackerImageUrl = project.fullUrl;
    let projectCode = project.path;

    return (
      <tr key={idx}>
        <td>
          <Envelope className="m-r-5" />
          {project.description}
        </td>
        <td>
          <div className="blue-msg d-flex justify-content-between">
            <div className="row">
              <div className="col-sm">
                {lastActivityMessage}
              </div>
              {project.lastActivityTime && (
                <div className="col-sm">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm m-l-5 text-center"
                    onClick={() => onClick(navigate, project)}
                  >
                    <i className="fas fa-globe fa-sm m-r-5"></i>
                    View activity details
                  </button>
                </div>
              )}

            </div>
          </div>
        </td>
        <td>
          <div className="blue-msg d-flex justify-content-between row">
            <div className="col-sm">

                <code>{projectCode}</code>

                <CopyToClipboard
                  text={embeddingCode}
                  onCopy={() => {
                    setCopySuccess(idx);
                    toast.success("Tracker code copied to clipboard");
                  }}
                >
                  <span title="Copy tracker to clipboard">
                    {copySuccess !== idx && <ClipboardFill className="m-l-5" />}
                    {copySuccess === idx && (
                      <ClipboardCheckFill className="m-l-5" />
                    )}
                  </span>
                </CopyToClipboard>

                <CopyToClipboard
                  text={trackerImageUrl}
                  onCopy={() => {
                    toast.success("Gmail tracker code copied to clipboard");
                  }}
                >
                  <span title="Copy tracker URL for Gmail">
                    <Google className="m-l-5" />
                  </span>
                </CopyToClipboard>
            </div>
            <div className="col-sm">

              <button
                type="button"
                className="btn btn-danger btn-sm m-l-5 text-center"
                onClick={() => showDeleteModal(project)}
              >
                <i className="fas fa-trash-can fa-sm m-r-5"></i>Delete tracker
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="card shadow m-b-20">
      <div className="card-header py-3">
        <h5 className="m-0 font-weight-bold">Mail trackers</h5>
      </div>
      <div className="card-body">
        <PushNotification />
        <div className="table-responsive">
          <table
            className="table table-bordered"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Description</th>
                <th>Last activity</th>
                <th>Tracker code</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Description</th>
                <th>Last activity</th>
                <th>Tracker code</th>
              </tr>
            </tfoot>
            <tbody>{entries}</tbody>
          </table>
        </div>

        <div className="m-b-20">
          <Formik
            initialValues={{ description: "" }}
            onSubmit={(values: Values, helperValues: FormikHelpers<Values>) => {
              onClickAdd(setProjects, values, helperValues);
            }}
            validationSchema={projectSchema}
          >
            {(props: FormikProps<Values>) => {
              const { touched, errors, isSubmitting } = props;
              return (
                <Form className="form-inline">
                  <div className="input-group">
                    <Field
                      type="text"
                      name="description"
                      placeholder="Enter new tracker description..."
                      className={`form-control bg-light small ${touched.description && errors.description
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <div className="input-group-prepend">

                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <i className="fas fa-plus fa-sm"></i> Add tracker
                      </button>
                      </div>
                      <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                      </div>
                </Form>
              );
            }}
          </Formik>
          <DeleteConfirmation
            showModal={deleteModalDisplay}
            confirmModal={submitDelete}
            hideModal={hideConfirmationModal}
            deletedObject={deleteModalObject}
            title="Delete tracker"
            message={deleteModalMessage}
          />
        </div>
      </div>
    </div>);
}

export default Project;
