import Static from "../Static";

type OutlookUsageState = {
  isOutlookApp: boolean;
  copySuccess: boolean;
};

class OutlookUsage extends Static<OutlookUsageState> {

  constructor(props: any) {
    super(props);
    this.state = { isOutlookApp: true, copySuccess: false };
  }

  componentDidMount() {
    this.props.setIsDarkbackgroundPage(false);

    const fragment = window.location.hash;

    if (fragment && fragment === '#web') {
      this.setOutlookWeb()
    }
  }

  componentWillUnmount() {
    this.props.setIsDarkbackgroundPage(true);
  }

  setOutlookApp() {
    this.setState({ isOutlookApp: true })
  }

  setOutlookWeb() {
    this.setState({ isOutlookApp: false })
  }

  onCopySuccess() {
    this.setState({ copySuccess: true })
  }

  render() {

    let outlookAppButtonClass, outlookWebButtonClass;

    if (this.state.isOutlookApp) {
      outlookAppButtonClass = 'btn btn-roundeded m-l-10 btn-dark'
      outlookWebButtonClass = 'btn btn-roundeded m-l-10 btn-light'

    } else {
      outlookAppButtonClass = 'btn btn-roundeded m-l-10 btn-light'
      outlookWebButtonClass = 'btn btn-roundeded m-l-10 btn-dark'
    }

    return (
      <div>

        <section id="page-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="content col-md-11">
                <div className="card">
                  <div className="card-body">
                    <h2>
                      Using mailcrumbs for Outlook
                    </h2>
                    <div className="row center">
                      <h4 className="">I am using...
                        <button
                          type="button"
                          className={outlookAppButtonClass}
                          onClick={(e) => this.setOutlookApp()}
                        >
                          <span>Outlook app on my computer</span>
                        </button>

                        <button
                          type="button"
                          className={outlookWebButtonClass}
                          onClick={(e) => this.setOutlookWeb()}
                        >
                          <span>Outlook on the web</span>
                        </button>
                      </h4>

                      <h3>Sending an email you want to track</h3>

                      <div>

                        {this.state.isOutlookApp && <div>

                          <img src="/img/outlook-icon-track.png" alt="" className="p-10 float-left" />

                          <p className="p-5">Compose a new email as you normally do and click the mailcrumbs <b>Track this email</b> button to track the email. As simple as that, you will receive a notification every time someone interacts with your email.</p>
                        </div>}

                        {!this.state.isOutlookApp && <div>

                          <img src="/img/outlook-icon-track.png" alt="" className="p-10 float-left" />

                          <p className="p-5">Compose a new email as you normally do and click the mailcrumbs <b>Track this email</b> button to track the email. As simple as that, you will receive a notification every time someone interacts with your email.</p>
                        </div>}
                      </div>

                      <h3>Reviewing where your emails have been</h3>

                      <div>

                        {this.state.isOutlookApp && <div>

                          <img src="/img/outlook-icon-show.png" alt="" className="p-10 float-left" />

                          <p>Go to the <b>Sent Items</b> folder in Outlook and select the message you want to review. Click on the <b>Show tracking results</b> button to show the summary of interactions with your email, and if you want more information on the recipients click on <b>View detailed email tracking</b>.</p>
                          <p><strong>Tip:</strong> you can click on the "pin" icon to automatically load tracking summaries for any of your sent emails.</p>
                        </div>}

                        {!this.state.isOutlookApp && <div>

                          <img src="/img/outlook-icon-show.png" alt="" className="p-10 float-left" />

                          <p>Go to the <b>Sent Items</b> folder in Outlook and select the message you want to review. Click on the  <b>Show tracking results</b> button to show the summary of interactions with your email, and if you want more information on the recipients click on <b>View detailed email tracking</b>.</p>
                          <p><strong>Tip:</strong> you can click on the "pin" icon to automatically load tracking summaries for any of your sent emails.</p>
                        </div>}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default OutlookUsage;
