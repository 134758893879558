import User from "../../../classes/User";

type HeaderProps = {
  user: User | null;
  colorScheme?: string;
  logout: () => void;
  isDarkbackgroundPage: boolean;
  isOffice: boolean;
};

function Header({
  user,
  colorScheme = "dark",
  logout,
  isDarkbackgroundPage,
  isOffice,
}: HeaderProps) {

  if (!isDarkbackgroundPage) {
    colorScheme = "light";
  }

  //                 <div className="logo-dark"><div style={{ display: 'inlineBlock', verticalAlign: 'middle', margin: '0', width: '100', height: 'auto'}}><span className="p-r-10"><Logo width={'100%'} height='auto'/></span>mailcrumbs2</div></div>

  return (
    <header
      id="header"
      data-transparent="true"
      className={`${colorScheme} submenu-light`}
    >
      <div className="header-inner">
        <div className="container">
          {!isOffice && (
            <div id="logo">
              <a href="/">
                <img src="/img/logo.svg" alt="logo" className="p-10 logo-default logo-dark float-left" /><span id="logo-text" className="logo-default logo-dark float-left">mailcrumbs</span>
                <img src="/img/logo.svg" alt="logo" className="p-10 logo-responsive float-left" /><span id="logo-responsive-text" className="logo-responsive float-left">mailcrumbs</span>
              </a>
            </div>
          )}
          {isOffice && (
            <div id="logo">
              <a href="#">
                <img src="/img/logo.svg" alt="logo" className="p-10 logo-default logo-dark float-left" /><span id="logo-text" className="logo-default logo-dark float-left">mailcrumbs</span>
                <img src="/img/logo.svg" alt="logo" className="p-10 logo-responsive float-left" /><span id="logo-responsive-text" className="logo-responsive float-left">mailcrumbs</span>
              </a>
            </div>
          )}

          {!isOffice && (

            <div id="mainMenu-trigger">
              <a className="lines-button x">
                <span className="lines"></span>
              </a>
            </div>
          )}

          {!isOffice && (
            <div id="mainMenu">
              <div className="container">
                <nav>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>

                    {!user && (
                      <li>
                        <a href="/login">Log In<i className="icon-login"></i></a>
                      </li>
                    )}

                    {user && (
                      <li className="dropdown">
                        <a href="/projects">My trackers<i className="icon-login"></i></a>
                      </li>
                    )}

                    {user && (
                      <li className="dropdown">
                        <a href="#">Help<i className="icon-login"></i></a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="/hc/outlook/install">Installing mailcrumbs for Outlook<i className="icon-login"></i></a>
                          </li>
                          <li>
                            <a href="/hc/outlook/usage">Using mailcrumbs for Outlook<i className="icon-login"></i></a>
                          </li>
                          <li>
                            <a href="/hc/gmail/usage">Using mailcrumbs for Gmail<i className="icon-login"></i></a>
                          </li>
                          <li>
                            <a href="/hc/generic/usage">Using mailcrumbs for other clients<i className="icon-login"></i></a>
                          </li>
                        </ul>
                      </li>
                    )}

                    {user && (
                      <li className="dropdown">
                        <a href="#">
                          <span>{user.username}</span>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a href="/logout"><i className="icon-log-out"></i>Logout</a>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
