import { useEffect, useState } from "react";
import "./App.css";

import TrackerMap from "./components/TrackerMap/TrackerMap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Layout from "./components/Layout/Layout";
import { Toaster } from "react-hot-toast";
import Project from "./components/Project/Project";
import { getLogs, getProjects } from "./services/DatastoreService";
import ProjectData from "./classes/ProjectData";
import LogData from "./classes/LogData";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import User from "./classes/User";
import { getUser } from "./services/UserService";
import { UserAPIResponse } from "./classes/APIResponse";
import Home from "./components/Static/Home/Home";
import Logout from "./components/Logout/Logout";
import OutlookInstall from "./components/Static/OutlookInstall/OutlookInstall";
import OutlookUsage from "./components/Static/OutlookUsage/OutlookUsage";
import Welcome from "./components/Static/Welcome/Welcome";
import GenericUsage from "./components/Static/GenericUsage/GenericUsage";
import GmailUsage from "./components/Static/GmailUsage/GmailUsage";
import Protected from "./components/Protected/Protected";
import Waiting from "./components/Waiting/Waiting";
import { getToken } from "./services/TokenManagement";
import PrivacyPolicy from "./components/Static/PrivacyPolicy/PrivacyPolicy";

const App = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isDarkbackgroundPage, setIsDarkbackgroundPage] = useState<boolean>(false);
  const [initialisationComplete, setInitialisationComplete] = useState<boolean>(false);

  useEffect(() => {
    initialisationComplete || initialise();
  });

  const initialise = async () => {

    const user = await fetchUserDetails();

    if (user) {
      setUser(user);
    }

    setInitialisationComplete(true);

    console.log("Initialisation complete")
  }

  const isUserloggedIn = (): boolean => {
    if (user != null) {
      console.log(`User is already logged in: ${JSON.stringify(user)}`)
    } else {
      console.log("User has not yet logged in")
    }

    return (user != null);
  }

  const setToken = async (
    rememberMe: boolean,
    userToken: string
  ): Promise<boolean> => {
    if (rememberMe) {
      localStorage.setItem("token", userToken);
    } else {
      sessionStorage.setItem("token", userToken);
    }

    setInitialisationComplete(false);
    initialise();

    return true;
  };

  const logout = (): void => {
    console.log("Logging out")
    sessionStorage.removeItem("token");
    localStorage.removeItem("token");

    setUser(null);
  };

  const fetchProjects = async (): Promise<ProjectData[]> => {
    return await getProjects();
  };

  const fetchLogs = async (
    projectId: string | undefined
  ): Promise<LogData[]> => {
    if (projectId) {
      return await getLogs(projectId);
    } else {
      return [];
    }
  };

  const fetchUserDetails = async () => {
    let user: User | null = null;

    const response: UserAPIResponse = await getUser();

    if (response.status === "ok" && response.data) {
      user = response.data;
    }

    const token: string | null = getToken();

    // Check if after the call was initiated the user logged out

    if (user && !token) {
      user = null;
    }

    return user;
  };

  const setIsDarkbackgroundPageWrapper = (isDarkbackgroundPage: boolean) => {
    setIsDarkbackgroundPage(isDarkbackgroundPage);
  };

  const browserRouter = createBrowserRouter([
    {
      element: (
        <Layout
          user={user}
          logout={logout}
          isDarkbackgroundPage={isDarkbackgroundPage}
          isOffice={false}
        />
      ),
      errorElement: <ErrorBoundary logout={logout} />,
      children: [
        {
          index: true,
          element: <Home setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />,
        },

        {
          path: "login",
          element: <Login setToken={setToken} user={user} isOffice={false} />,
          errorElement: <ErrorBoundary logout={logout} />,
        },

        {
          path: "logout",
          element: <Logout logout={logout} />,
          errorElement: <ErrorBoundary logout={logout} />,
        },

        {
          path: "register",
          element: <Register setToken={setToken} user={user} />,
          errorElement: <ErrorBoundary logout={logout} />,
        },

        {
          path: "projects",
          loader: fetchProjects,
          element: <Project />,
          errorElement: <ErrorBoundary logout={logout} />,
        },
        {
          path: "welcome",
          element: <Protected enabled={isUserloggedIn()}>
            <Welcome setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />
          </Protected>,
        },
        {
          path: "maps/:projectId",
          loader: ({ params }) => {
            return fetchLogs(params.projectId);
          },
          element: <TrackerMap />,
          errorElement: <ErrorBoundary logout={logout} />,
        },
        {
          path: "hc/outlook/install",
          element: <Protected enabled={isUserloggedIn()}>
            <OutlookInstall setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />
          </Protected>,
        },
        {
          path: "hc/outlook/usage",
          element: <Protected enabled={isUserloggedIn()}>
            <OutlookUsage setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />
          </Protected>,
        },
        {
          path: "hc/generic/usage",
          element: <Protected enabled={isUserloggedIn()}>
            <GenericUsage setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />
          </Protected>,
        },
        {
          path: "hc/gmail/usage",
          element: <Protected enabled={isUserloggedIn()}>
            <GmailUsage setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />
          </Protected>,
        },
        {
          path: "privacy",
          element: <PrivacyPolicy setIsDarkbackgroundPage={setIsDarkbackgroundPageWrapper} />
        },
        {
          path: "*",
          loader: ({ params }) => {
            throw new Response("Not Found", { status: 404 });
          },
          errorElement: <ErrorBoundary logout={logout} />,
        },
      ],
    },
  ]);

  return (
    <>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
            },
          },
          error: {
            style: {
              background: "red",
            },
          },
        }}
      />

      {initialisationComplete &&
        <RouterProvider router={browserRouter} />
      }

    </>
  );
};

/*
      {!initialisationComplete &&
        <Waiting />
      }

*/
export default App;
